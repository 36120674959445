import React from "react"
import Header from "../components/Header/Header"
import Layout from "../components/servicespage/Layout/Layout"
import Footer from "../components/Footer/Footer"
import { Helmet } from "react-helmet"

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Szolgáltatások - Jakab-Jánosi Eszter, érdi pszichológus</title>
        <link
          rel="alternate"
          media="only screen and (max-width: 1000px)"
          href="https://jakabjanosieszter.hu/szolgaltatasokmobil"
        ></link>
      </Helmet>
      <Header />
      <Layout />
      <Footer />
    </>
  )
}

export default Services
